import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const AtoudaAkademitAtidim = () => {

    useEffect(() => {
        document.title = 'Atouda Akademit & Atidim | Tsahal Connection';
      }, []);
      
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center'}}>Services</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Atouda Akademit et Atidim</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                Le programme Atouda Akademit permet de pouvoir effectuer des études académiques (B.A ou B.S.C) tout en repoussant le service militaire obligatoire.  Au lendemain ses études, le candidat s’engage à l’armée pour effectuer un service obligatoire suivi d’un prolongement de 3 ans. Le participant devient un officier pendant son service, ainsi le programme nécessite des résultats élevés au <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>. Surtout, le participant aura la garantie de Tsahal de pouvoir servir dans un poste en rapport avec son diplôme académique.<br />
                Les études peuvent se faire dans huit universités : université Tel-Aviv, université Ben Gurion, Open University, université Bar-Ilan, université Ariel, université hébraïque et le Technion. Il est aussi possible d’effectuer les études d’ingénierie dans différentes écoles (Afeka par exemple).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                    Dans le programme, il existe plusieurs parcours dont ceux dits « élites » :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                    <ul>
                        <li><strong>Tsameret - </strong><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>צמרת  -</strong></span> études de médecine.</li>
                        <li><strong>Psagot Hechmal - </strong><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>פסגות פיזיקה-חשבל -</strong></span> études d’électronique et de physique.</li>
                        <li><strong>Psagot Tokhna - </strong><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>פסגות תוכנה -</strong></span> étude de programmation.</li>
                        <li><strong>Barakim - </strong><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>בקרים -</strong></span> études de génie mécanique ou des matières.</li>
                        <li><strong>Silon - </strong><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>סילון  -</strong></span> génie aéronautique.</li>
                        <li><strong>Gvishim - </strong><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>גבישים -</strong></span> études en génie des matières (<span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}>הנדסת חומרים</span>) comprenant licence, master et une thèse.</li>
                        <li><strong>Alonim - </strong><span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}><strong>אלונים -</strong></span>  études de data science comprenant licence, master et une these.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                    Les participants au programme Atouda Akademit doivent effectuer 2 ans et 8 mois de service obligatoire et 3 ans de service supplémentaires (<span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}>שירות קבע</span>) ce qui porte la durée totale du service à 5 ans et 8 mois.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '2rem', fontWeight: '500', color: '#2f3036'}}>
                    Pour télécharger la liste des études possible via Atouda Akademit, cliquez <Link to="https://drive.google.com/file/d/1-0YLccHZ4p154pK9MmSVF9fVbsc9E9Az/view" target="_blank">ICI</Link>.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                    Inscription
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                    Tout d’abord, les participants sont dans l’obligation d’obtenir, un Dapar de 60 et une note de 590 au minimum (note générale-<span style={{fontSize: "20px", fontFamily: 'Rubik', fontWeight: '400'}}>רב תחומי</span>) à l’examen psychométrique (même si l’université demande une note inférieure).
                    Tsahal conseille de passer l’examen psychométrique entre la classe de première et de terminale. Voici un récapitulatif des démarches pour se joindre au programme Atouda Akademit : 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                1️⃣ <strong>Juillet-Août (entre la classe de première et terminale) :</strong>Jusqu’au mois de juin pour ceux dont l’entrée à l’armée est prévue jusqu’au mois d’avril.<br />
                2️⃣ <strong>Novembre-Avril : </strong>
                    <ul>
                        <li>Inscription au parcours Atouda via le site de l’armée.</li>
                        <li>Réception d’une autorisation de l’armée pour continuer les démarches.</li>
                        <li>Inscription dans une université ou école au lendemain de l’armée.</li>
                    </ul>
                3️⃣ <strong>Février-Juin :</strong> Examens individuels et journée de tests pour déterminer si le candidat peut participer à un programme d’élite. Cette journée de tests comprend: 
                    <ul>
                        <li>Test cognitif (équivalent à des tests de QI) avec des exercices de mathématiques, exercice avec des formes géométriques et des exercices littéraires (en hébreu).</li>
                        <li>Dynamique de groupe : en tant que futur officier, le but est de tester les capacités de leadership de chaque candidat et sa capacité à travailler avec les autres.</li>
                        <li>Tests de personnalité : certains tests vont jauger les capacités psychologiques des candidats à traverser les difficultés futures en tant qu'officier tandis que d'autres vont jauger les capacités professionnelles du candidat vis-à-vis du domaine d'études en tant qu'officier.</li>
                        <li>Entretien individuel : il se déroule avec un psychologue et l'objectif est de mieux connaitre les candidats et de vérifier leurs correspondances avec le parcours atouda akademit.</li>
                    </ul>
                4️⃣ <strong>Jusqu'à septembre :</strong> Réponse définitive de l’armée après réception des résultats aux baccalauréats.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                Les avantages financiers
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                    Il existe plusieurs aides financières pendant ce programme.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                    <ul>
                        <li>Bourse du programme Atouda à hauteur de <strong>10,500 NIS</strong> par an.</li>
                        <li>Bourse qui rembourse les frais d’inscriptions aux universités ou écoles, formations ou examens psychométriques à hauteur de <strong>2500 NIS</strong>.</li>
                        <li>Bourse d’excellence à hauteur de <strong>5000 NIS</strong>. Elle s’adresse aux 100 meilleurs étudiants annuels.</li>
                        <li>Bourse spéciale pour les candidats disposant de problèmes financiers, cette bourse dépend de plusieurs critères et varie entre <strong>5000 et 10,000 NIS</strong>.</li>
                        <li>Bourse spéciale à hauteur de <strong>5000 NIS</strong> pour les participants aux parcours d’élites. Les participants au parcours Tsameret reçoivent des aides supplémentaires concernant les appartements et au quotidien.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                    Les conditions de service
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                    Atouda Akademit est un parcours élitiste. Les conditions d’admission sont élevées, mais le service est passionnant et très enrichissant à l’aube des différentes carrières professionnelles de chacun.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                    <ul>
                        <li>Affectation et service en fonction des études effectuées.</li>
                        <li>Service en tant qu’officier dans Tsahal (inclus la période de service obligatoire). La formation d’officier s’effectuera lors de la première année du service obligatoire.</li>
                        <li>Tironout entre la première et la deuxième d’études (généralement durant l’été).</li>
                        <li>Salaire augmenté durant le service obligatoire.</li>
                        <li>Obligation de signer pour 3 ans supplémentaires au service obligatoire.</li>
                        <li>Possibilité d’effectuer des études de deuxième cycle universitaires pendant le service obligatoire. Ces études peuvent commencer à partir de la deuxième année du service obligatoire, si le commandant de l’unité l’autorise.</li>
                        <li>Autorisation pour voyager à l’étranger pendant les vacances du service obligatoire (à partir de la première année du service).</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                    Atidim - Année de préparation
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                Dans le cas où un élève de terminale souhaite effectuer une année de préparation académique, car il n'a pas été accepté dans Atouda Akademit, il peut demander à rejoindre le parcours Atidim. 
                Atidim est un organisme qui œuvre depuis 1999 aux renforcements et à l'excellence des futurs leaders de demain. Dans ce cadre a été créer la préparation Atidim qui permet aux candidats refusés par Atouda Akademit d'effectuer une année de préparation académique à l'université Hébraïque ou à l'université du Technion. Lors de cette année, les candidats bénéficient de cours de mathématique, d'anglais et d'une autre matière (en fonction de l'université). De plus, ils reçoivent aussi des formations à l'examen psychométrique.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                    Afin d'être accepté dans ce programme, il faut répondre aux conditions suivantes : <br />
                    ✔️ Baccalauréat avec 4-5 unités (yehidot) en anglais ET mathématiques.<br />
                    ✔️ Obtenir la note de 550 aux psychométriques.<br />
                    ✔️ Notes au <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>: 60 au Dapar.<br />
                    ✔️ Valider des tests de personnalité et un entretien avec le staff d'Atidim.<br />
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                    Chaque année, 80% des participants au programme préparatoire d'Atidim rejoignent par la suite le programme Atouda Akademit, preuve de l'excellence du programme.
                </Typography>
            </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default AtoudaAkademitAtidim;
  