import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';


const MentionsLegales = () => {
    const bulletListStyle = {
        listStyleType: 'circle',
        marginLeft: '1.5rem',
    };

    useEffect(() => {
        document.title = 'Mentions Légales | Tsahal Connection';
      }, []);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', textAlign: 'center' }}>Mentions Légales</h1>
                <img src={hr} alt="HR" width="80" height="50" />
            </div>
            <Container maxWidth="md" style={{marginTop: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '3rem'}}>
                    Le site internet à l’adresse www.tsahalco.com (ci-après désigné : « le Site ») est édité par l’association Ach Gadol, association immatriculée au registre israélien des associations sous le n° 580536985, dont le siège social est situé au 60 Weizmann à TEL AVIV (ISRAËL) – Téléphone : +972 54 905 60 16 (ci-après désignée : « la Société »).
                    Le directeur de la publication du Site est Monsieur Raphael MADAR, en sa qualité de directeur du projet Tsahal Connection appartenant à l’association Ach Gadol.<br />
                    Le Site est hébergé par : Wix.<br />
                    Le Site a été conçu afin de fournir des informations et des articles susceptibles d’intéresser les utilisateurs.<br />
                    L’accès au site et son utilisation sont assujettis aux Conditions Générales ci-après, ainsi qu’à toutes les lois en vigueur. En accédant au Site et en le parcourant, l’utilisateur accepte sans aucune restriction ni réserve l’intégralité de ces Conditions Générales.<br />
                    Outre ces Conditions Générales, les utilisateurs peuvent également être soumis à des conditions d’utilisation additionnelles spécifiques à certains services ou sites et qui leur seront communiquées lorsqu’ils y accéderont. 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', fontWeight: '700' }}>
                CONDITIONS GÉNÉRALES
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '15px', fontWeight: '500', marginBottom: '2rem'}}>
                Date de la dernière mise à jour : 01-10-2022.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
                1. UTILISATION DU SITE
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
                Le contenu du Site est protégé par le Droit d’auteur et ne peut être utilisé que conformément aux présentes Conditions Générales, aux mentions contenues dans le Site et, le cas échéant, après accord préalable et écrit de la Société.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
                2. ABSENCE D’ENGAGEMENT
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
                L’accès au site ne peut être considéré comme liant la Société à l’utilisateur, à quelque titre que ce soit. La présentation de produits, d’articles et/ou services sur le Site ne saurait constituer un engagement contractuel ni pour la Société, ni pour aucune autre personne morale ou physique.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
                3. RESPONSABILITÉ ET GARANTIES
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
                    3.1 La Société ne peut être tenue responsable, envers un utilisateur ou un tiers, de tout dommage découlant de l’utilisation du Site ou des informations qui y sont publiées, y compris les dommages accessoires ou indirects, ni, sans que cette liste soit limitative, la perte de données, de revenus, de chiffres d’affaire ou de bénéfices et les contestations, actions ou recours de tiers, même dans les cas où la Société aurait été informée de la possibilité d’un tel dommage. L’Utilisateur s’engage à assumer pleinement les risques liés au crédit qu’il accorde aux publications du Site.<br /> <br />
                    3.2 La Société s’efforcera, dans la limite du raisonnable, à éviter les fautes de frappe, erreurs de syntaxe, inexactitudes techniques, erreurs typographiques ou autres (ci-après désignées : « les erreurs »). La Société ne sera pas responsable de tout dommage découlant des erreurs.                <br /> <br />
                    3.3 Les informations publiées sur le Site sont fournies à titre gracieux et indicatif et ne sont assorties d’aucune garantie, explicites ou implicites de la part de la Société, obligeant la Société ou acquittant l’Utilisateur. L’Utilisateur renonce à toute plainte, remarque, exigence envers la Société concernant le contenu des informations publiées sur le Site et leur exactitude.<br /> <br />
                    3.4 Les informations ou les contenus du Site, ainsi que les Conditions Générales d’utilisation, peuvent être à tout moment modifiés ou retirés par la Société, sans que cette dernière n’ait besoin d’en informer les utilisateurs au préalable.<br /> <br />
                    3.5 La Société ne s’engage pas à ce que l’activité du Site ne soit pas interrompue, protégée de tout virus informatique ou contenu dangereux, protégée de bugs ou problèmes techniques et/ou tout problème limitant ou empêchant l’accès au Site ou étant indépendant de sa volonté.<br /> <br />
                    3.6 Les informations et contenus du Site sont valables au jour de leur publication. La Société n’est pas contrainte de devoir les actualiser.
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
                4. LIENS
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
                Les éventuels liens contenus dans le Site peuvent donner accès à des serveurs gérés par des tiers. La Société n’a aucun contrôle ni sur ces serveurs ni sur les informations contenues sur ces sites et ne peut garantir que les informations qui s’y trouvent soient exactes et correspondantes aux besoins de l’utilisateur.
                La décision d’activer les liens appartient exclusivement aux utilisateurs du Site. La Société décline formellement toute responsabilité quant aux contenus des sites vers lesquels elle offre des liens et ne saurait être responsable de l’accès par les utilisateurs par les liens figurant sur les dits sites, ou son propre site, à d’autres ressources présentes sur internet.   
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
                5. DONNÉES PERSONNELLES
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Veuillez-vous référer au chapitre suivant : Politique de Confidentialité. 
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            6. DROITS D’AUTEUR
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            6.1 Tous les éléments de toute nature, y compris, mais sans s’y limiter, les images fixes, images animées, bases de données, marques, illustrations, logos, dessins, modèles, mise en page, documents téléchargeables, contenus sur le Site sont la propriété exclusive de la Société et sont soumises aux lois israélienne et internationale sur le Droit d’auteur et la propriété intellectuelle, dès lors qu’ils sont mis à la disposition du public sur le Site. <br /> <br />
            6.2 Ces éléments demeurent la propriété exclusive de la Société, sauf mentions particulières. À ce titre, sauf autorisation préalable et écrite de la Société, toute reproduction, représentation, adaptation, modification partielle ou intégrale de tout élément composant le Site, par quelque moyen que ce soit, est interdite sous peine de poursuites judiciaires.<br /><br />
            6.3 Aucune licence, ni aucun droit autre que celui de consulter le Site, n’est conféré à quiconque au regard des Droits de propriété intellectuelle.
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            7. IMAGES, MARQUES ET LOGOS
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Les images de personnes, de lieux ou autre figurant sur le site sont soit la propriété de la Société soit utilisées avec l’autorisation des titulaires des droits. Aucun droit autre que celui de consulter les images sur le site n’est conféré à quiconque au regard des droits de propriété intellectuelle, sauf stipulation contraire ou autorisation préalable et écrite de la Société. L’autorisation d’utiliser ces images doit être demandée au responsable du Site, à l’adresse mail : <a href="mailto:info@tsahalco.com">info@tsahalco.com</a>. . Tous les autres logos et marques figurant dans le site appartiennent à leurs propriétaires respectifs. Aucune disposition du Site ne doit être interprétée comme conférant, expressément ou implicitement, une licence ou le droit d’utiliser une marque contenue dans le Site sans l’autorisation préalable et écrite de la Société ou du propriétaire de la marque.   <br />
            La Société entend faire pleinement respecter ses droits de propriété intellectuelle.
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            8. MISE À JOUR DES CONDITIONS GÉNÉRALES
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            La Société peut à tout moment modifier et actualiser les présentes Conditions Générales à sa propre discrétion. Les dispositions les plus récentes étant celles qui s’appliquent lorsque l’Utilisateur visite le Site, en conséquence, ce dernier devra les consulter régulièrement.
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            9. LOI APPLICABLE, TRIBUNAL COMPÉTENT
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '3rem'}}>
            Le contenu de ce site est régi par la loi israélienne. Tout différend ou litige lié directement ou indirectement à ce site sera de la compétence exclusive des juridictions de la ville de TEL AVIV, en ISRAËL.<br />
            Les Conditions Générales ont été traduites en français afin d’aider la compréhension du public francophone, mais en en cas de litige, seule la version en hébreu servira d’interprétation aux parties. <br />
            La version en hébreu est disponible <Link to='/https://drive.google.com/file/d/10dMNZthYHyhscarIJvtKGLgbf4NdQKK7/view' target="_blank" style={{color: '#1398aa'}}>ICI</Link>.
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', fontWeight: '700' }}>
            POLITIQUE DE CONFIDENTIALITÉ
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '15px', fontWeight: '500', marginBottom: '2rem'}}>
                Date de la dernière mise à jour : 15/12/2020
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            1. PROTECTION DE LA VIE PRIVÉE
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Soucieuse de garantir aux utilisateurs la protection de leurs données personnelles et de leur vie privée, la Société a adopté cette Politique de Confidentialité. La Politique de Confidentialité s’applique au site internet www.tsahalco.com (ci-après désigné : « le Site »). <br />
            La Politique de Confidentialité n’inclut pas les informations collectées hors ligne ou en ligne par l’intermédiaire de sources autres que le Site. 
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            2. ENREGISTREMENT
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Aucune inscription n’est requise pour accéder au site. Cependant, afin de se porter volontaire ou de bénéficier d’un accompagnement individuel gratuit, un formulaire devra être rempli. 
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            3. COLLECTE DES RENSEIGNEMENTS PERSONNELS
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Afin de devenir volontaires ou de bénéficier gratuitement d’un accompagnement individuel, les candidats doivent remplir un formulaire dont la politique de confidentialité est expliquée dans les points suivants.<br />
            Dans le cas d’un futur volontaire, nous collectons les renseignements suivants :  
            <ul>
                <li>Nom complet</li>
                <li>Genre/Sexe</li>
                <li>Date de naissance</li>
                <li>Numéro de téléphone (et numéro WhatsApp si différent)</li>
                <li>Adresse électronique</li>
                <li>Ville de résidence</li>
                <li>Type de service militaire</li>
                <li>Dates de début et de fin du service militaire</li>
                <li>Fonction et grade dans les rangs de Tsahal (dans le cas où le soldat a servi dans une unité secrète, il devra indiquer la branche de l’unité uniquement)</li>
            </ul>        
            Dans le cas où le candidat est un futur soldat ou un soldat recherchant un accompagnement individuel de notre part, nous collectons les renseignements suivants :
            <ul>
                <li>Prénom</li>
                <li>Genre/Sexe</li>
                <li>Date de naissance</li>
                <li>Numéro de téléphone (et numéro WhatsApp si différent)</li>
                <li>Adresse électronique</li>
                <li>Statut vis-à-vis de la religion juive (juif par la mère, juif converti ainsi que la date de conversion, père ou grand-parent juif)</li>
                <li>Statut d’enfant unique ou non</li>
                <li>Contact en cas d’urgence (son lien avec le contact, le nom complet du contact ainsi que son numéro de téléphone et son adresse mail)</li>
                <li>Numéro d’identité israélienne</li>
                <li>Son numéro de matricule militaire (dans le cas où le candidat est déjà en service)</li>
                <li>Nationalité(s) et numéro(s) de chaque passeport</li>
                <li>Statut vis-à-vis du ministère de l’Intérieur de l’État d’Israël (touriste, Israélien ayant quitté le territoire depuis ses 10 ans au maximum, nouvel immigrant israélien)</li>
                <li>Participation ou non dans un programme d’intégration (massa, yeshiva, école ou autre) et les détails sur ce programme (année scolaire et nom du programme)</li>
                <li>Année d’obtention de la nationalité israélienne (dans le cas où le candidat est israélien)</li>
                <li>Pays de résidence des parents du candidat</li>
                <li>Service militaire que le candidat souhaite effectuer, le type de service militaire souhaiter par le candidat (MAHAL, service obligatoire en tant qu’Israélien, études avant le service militaire)</li>
                <li>Résumer son parcours scolaire, son cadre de vie ainsi que les raisons qui le poussent à vouloir s’engager dans les rangs de TSAHAL</li>
            </ul>
            Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à l’interactivité établie entre vous et notre site Web. Nous utilisons également, comme indiqué dans la section suivante, des fichiers témoins et/ou journaux pour réunir des informations vous concernant.
          </Typography>
          <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            4. FORMULAIRES ET INTERACTIVITÉ
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Vos renseignements personnels sont collectés par le biais de formulaire, à savoir un formulaire d’inscription au site Web<br />
            Nous utilisons les renseignements ainsi collectés pour les finalités suivantes : 
            <ul>
                <li>Accompagnement individuel</li>
                <li>Statistiques</li>
                <li>Mettre en lien les différents candidats entre eux</li>
                <li>Mettre en lien les candidats avec d’anciens soldats afin que ceux-ci puissent leur transmettre leurs expériences militaires</li>
                <li>Amélioration de nos services</li>
            </ul>  
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            5. FICHIERS JOURNAUX ET TÉMOINS
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Nous recueillons certaines informations par le biais de fichiers journaux (log file) et de fichiers témoins (cookies). Il s’agit principalement des informations suivantes :
            <ul>
                <li>Adresse IP</li>
                <li>Système d’exploitation</li>
                <li>Pages visitées et requêtes</li>
                <li>Heure et jour de connexion</li>
            </ul>
            Le recours à de tels fichiers nous permet :
            <ul>
                <li>Amélioration du service et accueil personnalisé</li>
                <li>Profil personnalisé de consommation</li>
                <li>Statistique</li>
            </ul>
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            6.  PARTAGE DES RENSEIGNEMENTS PERSONNELS
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Nous nous engageons à ne pas commercialiser les renseignements personnels collectés. Toutefois, il nous arrive de partager ces informations avec des tiers pour les raisons suivantes :
            <ol>
                <li>
                    Pour les candidats souhaitant se porter volontaires : 
                    <ul>
                        <li>Ces informations seront traitées par le directeur du projet Tsahal Connection uniquement. Cependant, et uniquement avec son accord, le candidat peut être mis en contact directement avec les futurs soldats souhaitant en apprendre sur l’unité en question.</li>
                    </ul>
                </li>
                <li>
                    Pour les candidats souhaitant s’engager dans les rangs de TSAHAL : 
                    <ul>
                        <li>Les informations récoltées peuvent être transmises à l’organisation Qualita (noms, prénoms, adresses mail et ville de résidence uniquement) qui finance une partie du projet. Qualita s’engage à conserver ces informations uniquement afin de vérifier que le candidat ait bien reçu l’aide fournie par Tsahal Connection. Le reste des informations peut être transmis à TSAHAL ou au bureau de MAHAL du ministère de la Défense, dans le cas où une demande officielle au nom du candidat devrait être mise en place.</li>
                    </ul>
                </li>
            </ol>
            Si vous ne souhaitez pas que vos renseignements personnels soient communiqués à des tiers, il vous est possible de vous y opposer au moment de la collecte ou à tout moment par la suite, comme mentionnés dans la section suivante. Sachez cependant que cela nous oblige à ne plus fournir le service indiqué (accompagnement individuel ou transmission des données à Tsahal ou au Ministère de la Défense Israélienne).

            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            7. DROIT D’OPPOSITION ET DE RETRAIT
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Nous nous engageons à vous offrir un droit d’opposition et de retrait quant à vos renseignements personnels.
            Le droit d’opposition s’entend comme étant la possibilité offerte aux internautes de refuser que leurs renseignements personnels soient utilisés à certaines fins mentionnées lors de la collecte.<br />
            Le droit de retrait s’entend comme étant la possibilité offerte aux internautes de demander que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion.<br />
            Sachez cependant que cela nous oblige à ne plus fournir le service indiqué (accompagnement individuel ou transmission des données à Tsahal ou au ministère de la Défense Israélienne).<br />
            Pour pouvoir exercer ces droits, vous pouvez nous contacter par :<br />
            Adresse postale : Weizmann 60, Tel Aviv-Yafo 6215511<br />
            Courriel : <a href="mailto:info@tsahalco.com">info@tsahalco.com</a>. <br />
            Téléphone : 0549056016<br />
            Site web : http://www.tsahalco.com/.
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            8. DROIT D’ACCÈS
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Nous nous engageons à reconnaître un droit d’accès et de rectification aux personnes concernées désireuses de consulter, modifier, voire radier les informations les concernant. Sachez cependant que cela nous oblige à ne plus fournir le service indiqué (accompagnement individuel ou transmission des données à Tsahal ou au ministère de la Défense Israélienne).<br />
            L’exercice de ce droit se fera par :<br />
            Adresse postale : Weizmann 60, Tel Aviv-Yafo 6215511 <br />
            Courriel : <a href="mailto:info@tsahalco.com">info@tsahalco.com</a>.  <br />
            Téléphone : 0549056016 <br />
            Section du site web : http://www.tsahalco.com/
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            9. SÉCURITÉ
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
                Les renseignements personnels que nous collectons sont conservés dans un environnement sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations.
                Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures suivantes :
                <ul>
                    <li>Protocole SSL (Secure Sockets Layer)</li>
                    <li>Protocole SET (Secure Electronic Transaction)</li>
                    <li>Gestion des accès – personne autorisée</li>
                    <li>Gestion des accès – personne concernée</li>
                    <li>Logiciel de surveillance du réseau</li>
                    <li>Sauvegarde informatique</li>
                    <li>Développement de certificat numérique</li>
                    <li>Identifiant / mot de passe</li>
                    <li>Pare-feu (Firewalls)</li>
                </ul>
                Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières innovations technologiques permettant d’assurer la confidentialité de vos transactions. Toutefois, comme aucun mécanisme n’offre une sécurité maximale, une part de risque est toujours présente lorsque l’on utilise Internet pour transmettre des renseignements personnels.
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            10. STATISTIQUES D’UTILISATION DU SITE
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Lorsque vous visitez le site, certaines informations telles que votre adresse de protocole Internet (adresse IP), fournisseur de services Internet, système d’exploitation, le service à partir de laquelle vous êtes arrivé, la page que vous avez demandée, l’heure et la date de vos visites, et potentiellement d’autres statistiques d’utilisation du site seront automatiquement recueillies dans le cadre des processus de logiciels de la page internet. Ces informations ne permettent pas de vous identifier personnellement, mais pourraient fournir à la Société des renseignements personnels vous concernant. Ces statistiques permettent à la Société d’analyser l’efficacité de son site, d’y apporter les améliorations nécessaires et potentiellement d’optimiser la commercialisation de ses produits et ses services. Généralement, ces informations sont conservées aussi longtemps que nécessaires pour soutenir les améliorations du site et pourraient être partagées avec nos conseillers, agents ou autres tiers.
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            11. COOKIES
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Définition : un cookie est un fichier placé dans l’ordinateur de l’utilisateur du site et qui est géré par la Société, qui a procédé à l’installation de ce cookie. Le but des cookies est de permettre de reconnaître l’ordinateur d’un utilisateur du site internet lors de sa prochaine visite sur le Site. <br />
            Le Site utilise ces cookies pour collecter des informations temporaires concernant les visiteurs du Site. Cependant, toutes les informations collectées indirectement ne seront utilisées que pour suivre la quantité, le type et la configuration des visites sur le site, dans le but d’améliorer la qualité des services que fournit la Société à ses clients. <br />
            Chaque utilisateur du Site accepte que les cookies soient installés sur son ordinateur. Néanmoins, chaque internaute est libre de refuser les cookies en modifiant les paramètres de configuration de son navigateur internet.
             </Typography>
             <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
             12. PROTECTION DE L’ENFANCE
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Ce site est uniquement destiné aux adultes majeurs. Il n’est pas destiné aux enfants de moins de dix-huit ans, et la Société refuse de recevoir une quelconque information personnelle d’un internaute mineur.
             </Typography>
             <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
             13. POLITIQUE DE NON-VIOLENCE
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Dans le respect de la loi, le Site n’a pas pour vocation d’harceler des personnes, d’intimider, d’inciter à la haine ou à la violence, de publier des contenus pornographiques et/ou violents et/ou contenants de la nudité.
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            14. MISE À JOUR DE LA POLITIQUE DE CONFIDENTIALITÉ
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            La Société peut à tout moment modifier et actualiser sa Politique de Confidentialité à sa propre discrétion. Les dispositions les plus récentes étant celles qui s’appliquent lorsque vous visitez le site, nous vous demandons en conséquence de les consulter régulièrement.
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            15. LOI APPLICABLE, TRIBUNAL COMPÉTENT
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem'}}>
            Le contenu de ce site est régi par la loi israélienne. Tout différend ou litige lié, directement ou indirectement, à ce site et/ou à la protection de la vie privée des utilisateurs et/ou à la Politique de Confidentialité, sera de la compétence exclusive des juridictions de la ville de TEL-AVIV, en ISRAËL. <br />
            La Politique de Confidentialité a été traduite en français afin d’aider la compréhension du public francophone, mais en en cas de litige, seule la version en hébreu servira d’interprétation aux parties.  <br />
            La version en hébreu est disponible <Link to='/https://drive.google.com/file/d/10dMNZthYHyhscarIJvtKGLgbf4NdQKK7/view' target="_blank" style={{color: '#1398aa'}}>ICI</Link>.
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', fontWeight: '600', marginBottom: '1rem' }}>
            16. CONTACT
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '4rem'}}>
            La Société est déterminée à résoudre les plaintes que vous pourriez avoir concernant votre vie privée et la collecte et l’utilisation de vos renseignements personnels. Vous pouvez adresser vos éventuelles plaintes, remarques ou question au mail : <a href="mailto:info@tsahalco.com">info@tsahalco.com</a>.     
            </Typography>
            </Container>
            <Footer />
            </Fade>
        </div>
    );
};

export default MentionsLegales;
