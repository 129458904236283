import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
import Footer from '../../components/Home/Footer';
  import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const data = [
  {
    type: "Salaire Basique",
    col1: "Environ 1327₪",
  },
  {
    type: "Supplément en fonction du statut",
    col1: "0₪",
    col2: "Environ 599₪",
    col3: "Environ 1320₪",
    col4: "Environ 1949₪ après 2 ans",
  },
  {
    type: "Ramat Peïlout Aleph+",
    col1: "0 - 354₪"
  },
  {
    type: "Ramat Peïlout Aleph",
    col1: "0 - 178₪"
  },
  {
    type: "Ramat Peïlout Beth",
    col1: "0 - 124₪"
  },
  {
    type: "Ramat Peïlout Guimel",
    col1: "0 - 71₪"
  },
];

  const dataBoded = [
    {
      type: "Salaire Basique",
      col1: "Environ 1327₪",
    },
    {
      type: "Supplément en fonction du statut",
      col1: "0₪",
      col2: "Environ 599₪",
      col3: "Environ 1320₪",
      col4: "Environ 1949₪ après 2 ans",
    },
    {
      type: "Subvension soldat seul",
      col1: "601.2₪"
    },
    {
      type: "Ramat Peïlout Aleph+",
      col1: "0 - 467₪",

    },
    {
      type: "Ramat Peïlout Aleph",
      col1: "0 - 233₪",

    },
    {
        type: "Ramat Peïlout Beth",
        col1: "0 - 124₪",

      },
    {
      type: "Ramat Peïlout Guimel" ,
      col1: "0 - 71₪",
    },
    {
        type: "Aide au logement" ,
        col1: "Jusqu'à environ 1400₪",

      },
      {
        type: "Maintenance de l'appartement" ,
        col1: "Environ 350₪",

      },
      {
        type: "Olim Non-Mahal - Misrad Haklita" ,
        col1: "540₪",

      },
      {
        type: "Olim Non-Mahal - Misrad Habinouy" ,
        col1: "402₪",

      },
    ];

    const colors = {
      "Salaire Basique": "#FDDEF5",
      "Supplément en fonction du statut": "#FDDEF5",
      "Subvension soldat seul": "#FDDEF5",
      "Ramat Peïlout Aleph+": "#ECC6FF",
      "Ramat Peïlout Aleph": "#ECC6FF",
      "Ramat Peïlout Beth": "#ECC6FF",
      "Ramat Peïlout Guimel": "#ECC6FF",
      "Aide au logement": "#E2C3FF",
      "Maintenance de l'appartement": "#E2C3FF",
      "Olim Non-Mahal - Misrad Haklita": "#CBB5FE",
      "Olim Non-Mahal - Misrad Habinouy": "#CBB5FE",
    };
    
    const renderRow = (row, index) => {
      const color = colors[row.type] || '#FFFFFF'; // Default color if type not found
      return (
        <TableRow key={index}>
          <TableCell style={{ fontWeight: '600', fontFamily: 'Montserrat', backgroundColor: color  }}>{row.type}</TableCell>
          {row.col1 && row.col2 && <TableCell style={{fontFamily: 'Montserrat', textAlign: 'center'}}>{row.col1}</TableCell>}
          {row.col2 && <TableCell style={{fontFamily: 'Montserrat', textAlign: 'center'}}>{row.col2}</TableCell>}
          {row.col3 && <TableCell style={{fontFamily: 'Montserrat', textAlign: 'center'}}>{row.col3}</TableCell>}
          {row.col4 && <TableCell style={{fontFamily: 'Montserrat', textAlign: 'center'}}>{row.col4}</TableCell>}
          {!row.col2 && !row.col3 && !row.col4 && <TableCell colSpan={4} style={{fontFamily: 'Montserrat', textAlign: 'center'}}>{row.col1}</TableCell>}
        </TableRow>
      );
    };

  
const HayalBoded = () => {

    useEffect(() => {
        document.title = 'Hayal Boded | Tsahal Connection';
    }, []);
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Annexes</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Hayal Boded & Salaires</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '2rem', fontWeight: '500', color: '#2f3036'}}>
                    On compte près de 7000 soldats seuls qui servent dans les rangs de Tsahal. Certains le sont parce que leurs parents habitent à l'étranger, d'autres parce qu'ils ont abandonné leurs communautés pour poursuivre leurs rêves de servir dans les rangs de Tsahal. De ce fait, ce statut octroie à tous ces soldats et soldates de nombreuses aides qui se révèlent précieuse pour leurs quotidiens.<br />
                    </Typography>
                    <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#eafcff', borderColor: '#eafcff', marginBottom: '2rem', marginTop: '1rem'}}>
                            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1', fontSize: '20px', color: '#2f3036', fontWeight: '600', padding: '20px'}}>
                            ⬇️ Pour télécharger la brochure sur les droits des soldats seuls, cliquez <Link to='https://drive.google.com/file/d/113bs_CwmfIpS5nBJCqwooEa2IJe4KvcE/view' style={{color: '#1398aa'}} target="_blank">ICI</Link>.
                            </Typography> 
                    </div>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        Salaires dans Tsahal
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        Un soldat reçoit son salaire le premier du mois. Ainsi s’il s’est enrôlé le 14 août, il recevra son premier salaire le 1er septembre et celui-ci sera proportionnel au nombre de jours en tant que soldat.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        Lors des différentes formations à <Link to='/michve-alon' style={{color: '#1398aa'}}>Michve Alon</Link>, les soldats ont un statut de non-combattant.<br />
                        Les soldats servant dans les unités combattantes reçoivent un salaire de combattant uniquement après être « Rovai 05 » (généralement après 4 mois de formation). <br />
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        Le Ramat Peïlout (niveau d’activité militaire) dépend du poste, de la période du service militaire, de la dangerosité des tâches quotidiennes et de beaucoup d’autres facteurs. Ainsi, il est possible qu'il varie plusieurs fois pendant le service.<br />
                        Les détails du tableau ci-dessous sont approximatifs et il est important de comprendre que le salaire d'un soldat dépend de beaucoup de paramètre (combien de jours en base fermée, le statut du soldat, s'il se trouve en formation, s'il est en vacances, etc.). <br />
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        Il est possible et conseillé de vérifier son salaire sur le site <Link to='/https://www.prat.idf.il/' style={{color: '#1398aa'}} target='_blank'>https://www.prat.idf.il/</Link> (il faut s'inscrire avec le numéro d'identité ou numéro d'identité fictif pour les soldats de Mahal, et le numéro indiqué sur la carte de matricule-Hoger). Le soldat peut recevoir un salaire différent de ce qui est présenté et il est important d'apprendre à lire la fiche de paye. 
                    </Typography>
                    <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px'}}>
                            ❗ <strong>Liste des postes reconnus en tant que Lohem Hod -</strong> Givati, Nahal, Golani, Tsanhanim (parachutiste), Kfir, Handassa Kravit (génie militaire), Tothanim (artillerie), Shirion (tankiste), mécaniciens d’engins aériens accompagnant les pilotes en territoire ennemis, ainsi que les unités Satilim, Dabburim et Tsolelot (unités combattantes marines). Les soldats et soldates de la police des frontières (Magav) sont considérés comme Lohem mais reçoivent le supplément d’un Lohem Hod sur la troisième année de service.                            </Typography> 
                    </div>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        Tableaux des salaires
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                    Les montants indiqués sur chaque paramètre peuvent varier en fonction de beaucoup de paramètres et servent uniquement pour planifier les salaires. Ainsi il est très rare d'obtenir un salaire fixe chaque mois et il est important que les soldats apprennent à lire leurs fiches de paye disponibles sur le site <Link to='/https://www.prat.idf.il/' style={{color: '#1398aa'}} target='_blank'>https://www.prat.idf.il/</Link> .
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '3rem' }}>
                    Afin de recevoir le salaire, il faut avoir un compte bancaire israélien et donner les informations bancaires le jour de l'enrôlement. Le soldat recevra à priori trois virements :
                            <ul>
                                <li>Le salaire avec toutes les aides de l’armée le 1er de chaque mois.</li>
                                <li>Acte de naissance apostillé des frères/sœurs.La subvention du ministère du Logement et de la Construction (Misrad Habinouy) : généralement au début du mois.</li>
                                <li>La subvention du ministère de la aliyah et de l'intégration (Misrad Aaliyah VeHaklita) : généralement au début du mois.</li>
                            </ul>
                    </Typography>
                
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', marginBottom: '3rem', fontWeight: '700', textAlign: 'center' }}>
                    Salaires soldats
                </Typography>
                <TableContainer component={Paper} style={{marginBottom: '4rem'}}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Type de Soldat/Salaire</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Non-Combattant (Jobnik)</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Soutien au combat (Tomeh Lehima)</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Combattant (Lohem)</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Combattant Hod (Lohem Hod)</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {data.map((row, index) => renderRow(row, index))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '30px', marginBottom: '3rem', fontWeight: '700', textAlign: 'center' }}>
                    Salaires soldats seuls
                </Typography>
                <TableContainer component={Paper} style={{marginBottom: '4rem'}}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Type de Soldat/Salaire</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Non-Combattant (Jobnik)</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Soutien au combat (Tomeh Lehima)</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Combattant (Lohem)</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Combattant Hod (Lohem Hod)</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {dataBoded.map((row, index) => renderRow(row, index))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
};

export default HayalBoded ;
