import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import Navbar from '../../components/Features/Navbar';
import Footer from './../../components/Home/Footer'

import './../../styles/expert-co.css'

const SuiviClassique = () => {
  const [open, setOpen] = useState(true); // Dialog opens automatically on component mount
  const [acceptedGCU, setAcceptedGCU] = useState(false);

  useEffect(() => {
    document.title = 'Suivi Classique | Tsahal Connection';
  }, []);

  const handleAcceptGCU = () => {
    setAcceptedGCU(true);
    setOpen(false); // Close dialog only when GCU is accepted
  };



  return (
    <div>
      <Navbar />
      {acceptedGCU ? (
        <div>
          <iframe
            src='https://mrng.to/LARNpjDLdc'
            style={{ width: '100%', minHeight: '100vh', border: 'none', marginTop: '1em' }}
          ></iframe>
          <Footer />
        </div>
      ) : (
        <div>
          <Dialog
            open={open}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                setOpen(false);
              }
            }} // Only allow dialog to close for reasons other than clicking outside or pressing escape
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
          >
            <DialogContent>
              <iframe
                src="https://drive.google.com/file/d/1eV2dWgtLmGsXmQGCUb4kzQ581i94yKB6/preview"
                style={{ width: '100%', height: '500px', border: 'none' }}
                title="GCU Document"
              ></iframe>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAcceptGCU}>Accepter le contrat</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default SuiviClassique;
