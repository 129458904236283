import React, {useEffect, useState}from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import axios from 'axios';
import Home from '../../pages/Home';
import Login from '../../pages/Login';
import Register from "../../pages/Register";
import Profile from '../../pages/AfterAuth/Profile';
import NotreHistoire from '../../pages/A propos/NotreHistoire';
import NosActions from '../../pages/A propos/NosActions';
import Miyunim from '../../pages/Convocations/Miyunim';
import YomSayerot from '../../pages/Convocations/YomSayerot';
import TsavRichon from '../../pages/Convocations/TsavRichon';
import YomHamea from '../../pages/Convocations/YomHamea';
import MiyunLohamot from '../../pages/Convocations/MiyunLohamot';
import Giyus from '../../pages/Convocations/Giyus';
import Tironout from '../../pages/Annexes/Tironout';
import ConditionService from '../../pages/Annexes/ConditionsService';
import MichveAlon from '../../pages/Annexes/MichveAlon';
import EnfantUnique from '../../pages/Annexes/EnfantUnique';
import PratiqueReligion from '../../pages/Annexes/PratiqueReligon';
import HayalBoded from '../../pages/Annexes/HayalBoded';
import Admin from '../../pages/AfterAuth/Admin';
import Formulaire from '../../pages/Annexes/Formulaire';
import Mechinot from '../../pages/Annexes/Mechinot';
import StatutResident from '../../pages/Annexes/StatutResident';
import GarinTzabar from '../../pages/Services/GarinTzabar';
import ExpertConnection from '../../pages/ExpertConnection';
import AtoudaOlimAkadem from '../../pages/Services/AtoudaOlimAkadem';
import YoudGuimelDaleth from '../../pages/Services/YoudGuimelDaleth';
import Haredim from '../../pages/Services/Haredim';
import AtoudaAkademitAtidim from '../../pages/Services/AtoudaAkademitAtidim';
import ServiceComplet from '../../pages/Services/ServiceComplet';
import Mahal from '../../pages/Services/Mahal';
import AchGadol from '../../pages/AchGadol';
import Postes from '../../pages/Postes';
import Consignes from '../../pages/AfterAuth/Consignes';
import ConsignesTest2 from '../../pages/AfterAuth/ConsignesTest2';
import TesResulats from '../../pages/AfterAuth/TesResultats';
import ScrollToTop from './ScrollToTop';  // Importez le composant ScrollToTop ici
import SuiviClassique from '../../pages/PaymentPages/SuiviClassique';
import SuiviExpert from '../../pages/PaymentPages/SuiviExpert';
import ServiceEntretien from '../../pages/PaymentPages/ServiceEntretien';
import ServiceRishonHamea from '../../pages/PaymentPages/ServiceRIshonHamea';
import ServiceEval from '../../pages/PaymentPages/ServiceEval';
import ServiceOrientationPostes from '../../pages/PaymentPages/ServiceOrientationPostes';
import MentionsLegales from '../../pages/A propos/MentionsLegale';



const Index = () => {

    const [uid, setUid] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state
    const [isAdmin, setIsAdmin] = useState(false);
    const [isConnected, setIsConnected] = useState(false);

    // useEffect(() => {
    //   const fetchToken = async () => {
    //     try {
    //       const response = await axios.get('http://localhost:5000/jwtid', { withCredentials: true });
    //       setUid(response.data);
    //       console.log(response.data);
    //       setLoading(false);

    //     } catch (error) {
    //       console.log('No token');
    //     }
    //   };
  
    //   fetchToken();
    // }, [uid]);

    // if (loading) {
    //     return (
    //         <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', alignItems: 'center'}}>
    //           <CircularProgress />
    //         </Box>
    //       );    
    // }
    // const isAdmin = uid === '6565e111c04274cef08da41a'
    // const isConnected = uid !==  'No token';
    // console.log(isConnected)
    // console.log(isAdmin);

    const RedirectToExternal = ({ url }) => {
        useEffect(() => {
            window.location.href = url;
        }, [url]);
        return null;
    };
    
    
    return(
        <Router>
        <ScrollToTop />
            <Routes>
              {isAdmin ? (
                  <>
                      <Route path='/admin' element={<Admin />} />
                      <Route path='/profile' element={<Navigate replace to="/admin" />} />
                      <Route path='/login' element={<Navigate replace to="/admin" />} />
                      <Route path='/register' element={<Navigate replace to="/admin" />} />
                  </>
                  ) : isConnected ? (
                      <>
                      <Route path='/profile' element={<Profile />} />
                      <Route path='/test-comprehension' element={<Consignes />} />
                      <Route path='/test-comprehension-exam-2' element={<ConsignesTest2 />} />
                      <Route path='/tes-resultats' element={<TesResulats />} />
                      <Route path='/admin' element={<Navigate replace to="/profile" />}/>
                      <Route path='/login' element={<Navigate replace to="/profile" />}/>
                      <Route path='/register' element={<Navigate replace to="/profile" />}/>

                      </>
                  ) : (
                      <>
                      <Route path="/profile" element={<Navigate replace to="/login" />} />
                      <Route path='/admin' element={<Navigate replace to="/login" />} />
                      <Route path='/tes-resultats' element={<Navigate replace to="/login" />} />
                      <Route path='/test-comprehension' element={<Navigate replace to="/login" />} />
                      <Route path='/test-comprehension-exam-2' element={<Navigate replace to="/login" />} />
                  </>
                  )}
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/notre-histoire" element={<NotreHistoire />} />
                <Route path="/mentions-legales" element={<MentionsLegales />} />
                <Route path="/nos-actions" element={<NosActions />} />
                <Route path='/miyunim' element={<Miyunim />} />
                <Route path='/yom-sayerot' element={<YomSayerot />} />
                <Route path='/tsav-richon' element={<TsavRichon />} />
                <Route path='/yom-hamea' element={<YomHamea />} />
                <Route path='/miyun-lohamot' element={<MiyunLohamot />} />
                <Route path='/giyus' element={<Giyus />} />
                <Route path='/tironout' element={<Tironout />} />
                <Route path='/conditions-service' element={<ConditionService />} />
                <Route path='/michve-alon' element={<MichveAlon />} />
                <Route path='/enfant-unique' element={<EnfantUnique />} />
                <Route path='/religion' element={<PratiqueReligion />} />
                <Route path='/hayal-boded' element={<HayalBoded />} />
                <Route path='/carnet-forms' element={<Formulaire />} />
                <Route path='/mechinot' element={<Mechinot />} />
                <Route path='/status-resident' element={<StatutResident />} />
                <Route path='/garin-tzabar' element={<GarinTzabar />} />
                <Route path='/expert-connection' element={<ExpertConnection />} />
                <Route path='/atouda-olim-akademisatzia' element={<AtoudaOlimAkadem />} />
                <Route path='/youdguimdel-dalet' element={<YoudGuimelDaleth />} />
                <Route path='/haredim' element={<Haredim />} />
                <Route path='/service-complet' element={<ServiceComplet />} />
                <Route path='/atouda-akademit-atidim' element={<AtoudaAkademitAtidim />} />
                <Route path='/mahal' element={<Mahal />} />
                <Route path='/postes' element={<Postes />} />
                <Route path='/ach-gadol' element={<AchGadol />} />
                <Route path='/suivi-classique' element={<SuiviClassique />} />
                <Route path='/suivi-expert' element={<SuiviExpert />} />
                <Route path='/entretien-individuel' element={<ServiceEntretien />} />
                <Route path='/simulation-tsav-richon-yom-hamea' element={<ServiceRishonHamea />} />
                <Route path='/evaluation-physique-mentale' element={<ServiceEval />} />
                <Route path='/orientation-individuelle-postes' element={<ServiceOrientationPostes />} />
                <Route path="/contact" element={<RedirectToExternal url="https://zfrmz.com/FTnbHM1LHl4BWoGiMEqo" />} />
              </Routes>
        </Router>
    )
}

export default Index;