import React, { useEffect, useState } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography, Button } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import ModelSayerotGarcons from './../../images/ModelSayerotGarcons.jpg'
import ModelSayerotFilles from './../../images/ModelSayerotFille.jpg'
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const YomSayerot = () => {
    const [activeSet, setActiveSet] = useState(1);

    const handleSetChange = (setNumber) => {
        setActiveSet(setNumber);
    };

    useEffect(() => {
        document.title = 'Yom Sayerot | Tsahal Connection';
      }, []);
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Convocations</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Yom Sayerot</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '4rem', fontWeight: '500', color: '#2f3036'}}>
                    Yom Sadé » ou anciennement connu sous le nom de « Yom Sayerot », est une journée d’examens physiques pour les garçons uniquement et a pour but de tester les capacités physiques et mentales des participants.<br /> En fonction des résultats, les participants pourront ou non continuer dans différentes unités spéciales comme détaillées dans le modèle Sayerot. Il est important de savoir que le Yom Sayerot peut bloquer les tests à d’autres unités. Les candidats qui sont dans le processus pour les tests de pilote ne pourront pas faire les journées de tests qui s’en suivent tant qu’ils restent dans le processus des tests de pilote.
                    </Typography>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '2em'}}>
                    <Button
                            variant="outline-secondary"
                            style={{
                                borderRadius: '20px',
                                border: '1px solid #6c757d',
                                margin: '5px',
                                color: activeSet === 1 ? 'black': '#e0e0e0',
                                backgroundColor: activeSet === 1 ? '#f8f9fa' : 'transparent',
                                }}
                            onClick={() => handleSetChange(1)}
                        >
                            Modele garçons
                        </Button>
                        <Button
                            variant="outline-secondary"
                            style={{
                                borderRadius: '20px',
                                border: '1px solid #6c757d',
                                margin: '5px',
                                color: activeSet === 2 ? 'black': '#e0e0e0',
                                backgroundColor: activeSet === 2 ? '#f8f9fa' : 'transparent',
                                }}
                            onClick={() => handleSetChange(2)}
                        >
                            Modele fille
                        </Button>
                    </div>
                    <img 
                            src={activeSet === 1 ? ModelSayerotGarcons : ModelSayerotFilles} 
                            alt={activeSet === 1 ? "Pic Modele Sayerot Garcons" : "Pic Modele Sayerot Fille"} 
                            style={{ width: '100%', maxWidth: '100%', height: 'auto', borderStyle: 'solid', borderWidth: '1px', borderRadius: '20px', borderColor: '#e7e3e8', boxShadow: '0 1px 8px grey', marginBottom: '4rem'}}
                        />
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                   Les sessions d'examens
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                        Il existe deux sessions : janvier (date d’incorporation entre août et novembre) et octobre (date d’incorporation en mars-avril).<br />
                    </Typography>   
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                        Pour ceux qui participent à la formation d’hébreu de <Link to='/michve-alon' style={{color: '#1398aa'}}>Michve Alon</Link> aux sessions d’Août ou de décembre, il est possible de s’inscrire au Yom Sayerot, cependant si celui-ci tombe à la même période pour le test des parachutistes, le candidat devra probablement effectuer un choix entre les deux tests.<br />        
                    </Typography> 
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                        Il faut apporter un justificatif d’identité en norme (pièce d’identité, passeport, permis de conduire), ainsi qu’une autorisation d’un médecin signé par le médecin de famille et qui n’excède pas 90 jours en arrière depuis la journée du «Yom Sadé». Un participant qui vient sans l’un deux papiers se verra refuser l’accès au test. Les participants doivent apporter des affaires et des chaussures de sport, deux t-shirts avec des manches courtes. Il faut aussi amener des affaires de rechange.<br />
                    </Typography> 
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '2rem' }}>
                        En janvier, les tests se déroulent pendant la journée de 7h jusqu’en fin de journée.<br />
                        En octobre, les tests se déroulent aussi pendant la nuit, de 16h jusqu’au début d’après-midi de la journée suivante.<br />
                        Le « Yom Sadé » se déroule aux alentours de Rishon Letzion. 
                    </Typography> 
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                    Les conditions de participation
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036' }}>
                    Les conditions officielles sont:
                        <ul>
                            <li><strong>Profil médical -</strong> au minimum 82 pour les garçons et 97 pour les filles.</li>
                            <li><strong>Dapar -</strong> au minimum 50.</li>
                        </ul>
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                        Un participant répondant à ces conditions recevra automatiquement une convocation. Si ce n’est pas le cas, il faut faire une demande auprès de l’armée. Pour ceux qui ne remplissent pas ces critères, il est tout de même possible de faire une demande spéciale.<br />    
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem' }}>
                        Pour ceux qui participent à la formation d’hébreu de <Link to='/michve-alon' style={{color: '#1398aa'}}>Michve Alon</Link>  aux sessions d’Août ou de Décembre, il est possible de s’inscrire au Yom Sayerot, cependant si celui-ci tombe à la même période pour le test des parachutistes, le candidat devra probablement effectuer un choix entre les deux tests.<br />
                        Il faut apporter un justificatif d’identité en norme (pièce d’identité, passeport, permis de conduire). Ainsi qu’une autorisation d’un médecin signé par le médecin de famille et qui n’excède pas 90 jours en arrière depuis la journée du «Yom Sadé».<br />
                        Un participant qui vient sans l’un deux papiers se verra refuser l’accès au test. Les participants doivent apporter des affaires et des chaussures de sport, deux t-shirts avec des manches courtes. Il faut aussi amener des affaires de rechange.
                    </Typography>  
                </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default YomSayerot;
  