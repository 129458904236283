    import React, { useEffect, useState } from 'react';
    import Navbar from "../../components/Features/Navbar";
    import hr from "./../../images/icons/hr.png";
    import { Container, Typography, Button } from '@mui/material';
    import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
    import Footer from '../../components/Home/Footer';
    import { Link } from 'react-router-dom';
    import { Fade } from 'react-awesome-reveal';


    const TsavRichon = () => {

        const email = 'joinidf@mail.idf.il';

        const cardData = [
            {
                title: 'Entretien individuel',
                text: 'Entretien d’une trentaine de minutes visant à vérifier les informations administratives du candidat.'
            },
            {
                title: 'Tests psychotechniques',
                text: "Examens sur l’ordinateur qui peut se faire en hébreu ou en français. L’examen en hébreu comporte des analogies verbales, analogies géométriques et de l’algèbre (problèmes et calculs) tandis que l’examen en français ne comprend pas les analogies verbales"
            },
            {
                title:'Vérification des informations',
                text: "Entretien d’une trentaine de minutes visant à vérifier les informations administratives du candidat.",
            },
            {
                title: "Test d'hébreu",
                text: " Il se déroule à la fin de l’entretien individuel (ou du imout netounim pour les nouveaux immigrants possédant un niveau d’hébreu trop faible). Il s’agit d’un test oral suivi d’un test écrit sur l’ordinateur (différent de l’examen psychotechnique"
            },
            {
                title: "Entretiens médicaux",
                text: "Rencontre avec un médecin (femme ou homme en fonction du sexe du candidat). Celui-ci va déterminer le profil médical du candidat en fonction des documents fournis préalablement et en fonction des antécédents médicaux."
            },
            {
                title: "Rendez-vous chez un psychiatre",
                text: "Certains candidats peuvent être amenés à avoir ce type de rdv afin de recevoir une évaluation mentale pour le service militaire."
            },
            {
                title: "Reayon Poked (Mahal Uniquement)",
                text: "Entretien pour vérifier la mentalité du candidat au programme Mahal."
            },
            {
                title: "Assistance sociale (Mahal uniquement)",
                text: "Entretien qui consiste à recevoir des explications sur les aides sociales possible et sur les droits des futurs soldats, notamment pour les soldats seuls. Les candidats du programme Mahal feront cet entretien pendant le Tsav Rishon tandis que les olims l’effectueront via un formulaire à remplir sur le site Mitgaisim et un entretien téléphonique."
            },
            {
                title: "Entretien branche Haredit (Mahal Haredi uniquement)",
                text: "Entretien avec la branche religieuse de Tsahal pour confirmer l’inscription aux unités religieuses de Tsahal via le programme Mahal Haredi."
            }
        ];
        
        const [cards, setCards] = useState(new Array(9).fill(false));

        const toggleCard = (index) => {
            const newCards = [...cards];
            newCards[index] = !newCards[index];
            setCards(newCards);
        };

        useEffect(() => {
            document.title = 'Tsav Richon | Tsahal Connection';
        }, []);
        return (
            <div>
                <Navbar />
                <Fade triggerOnce>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                    <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Convocations</h1>
                    <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Tsav Richon</h1>
                    <img src={hr} alt="HR" width="80" height="50" />
                    <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '3rem', fontWeight: '500', color: '#2f3036'}}>
                        Le Tsav Rishon est la première rencontre entre l’armée et le futur soldat. Plusieurs entretiens ainsi que des examens sont prévus pour cette journée dont l’importance est maximale. Cette journée s’effectue dans un des centres de recrutement (en fonction de votre lieu d’habitation) : Tel Hashomer, Tibériade, Beer Shéva, Haïfa et Jérusalem.
                        À l’issu de cette journée, les candidats recevront plusieurs notes qui influenceront en grande partie la suite du processus militaire. Cette journée peut s’effectuer en plusieurs fois. Il est possible de se préparer à cette journée via notre branche Expert Connection.
                        </Typography>
                        <Typography variant="h3" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        Service Militaire en Israël : Quand ?
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            Les futurs soldats recoiventcette convocation à partir de 16 ans et 4 mois. Pour un olé, l’année qui suit la alyah l’exempte de recevoir toute convocation, car Tsahal considère qu’il faut s’intégrer lors de cette période. Si un olé est arrivé en Israël assez tôt, il fera les démarches au même moment que les Israéliens. Sinon, il a la possibilité d’annuler l’année d’intégration afin de recevoir une convocation pour le Tsav Rishon au plus vite via la branche olim de Tsahal au <a href={`mailto:${email}`}>{email}</a>.
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                        Les candidats au programme <Link to='/mahal' style={{color: '#1398aa'}}>Mahal</Link> recevront une convocation par mail quelques jours précédent la journée. Généralement, cela s’effectue avant le programme pré-armée.
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '4rem' }}>
                        Les candidats au programme <Link to='/garin-tzabar' style={{color: '#1398aa'}}>Garin Tzabar</Link> effectueront leurs Tsav Rishon pendant leurs périodes au Kibboutz/centre d’intégration lors de leurs arrivées en Israël. Les responsables de Garin Tzabar fixent les rendez-vous en partenariat avec Tsahal.
                        </Typography>
                        <Typography variant="h3" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                        Le déroulement de la journée
                        </Typography>
                        <div style={{marginBottom: '4rem'}}>
                        {cardData.map((data, index) => (
                            <div key={index} style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '1rem', marginBottom: '1rem' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <Typography
                                        variant="body1"
                                        style={{ fontFamily: 'Montserrat', fontSize: '20px', fontWeight: '600', color: '#2f3036', textTransform: 'uppercase', marginTop: '0.5rem'}}
                                    >
                                        {data.title}
                                    </Typography>
                                    <div>
                                        <Button
                                            variant="text"
                                            onClick={() => toggleCard(index)}
                                            style={{ fontFamily: 'Montserrat', fontSize: '20px', fontWeight: '500', color: '#2f3036', outline: 'none' }}
                                        >
                                            {cards[index] ? '-' : '+'}
                                        </Button>
                                    </div>
                                </div>
                                {cards[index] && (
                                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginTop: '1rem' }}>
                                        {data.text}
                                    </Typography>
                                )}
                            </div>
                        ))}
                        </div>

                        <Typography variant="h3" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700' }}>
                            Comprendre vos résultats
                        </Typography>
                        <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px' }}>
                            ⚠️ Il est possible d’effectuer une demande pour refaire l’examen psychotechnique. Cela va dépendre du niveau scolaire du candidat. Les conditions pour effectuer les demandes changent fréquemment et sont disponible sur le site Mitgaisim.<br />
                            Il est important de savoir que la meilleure note des deux tests sera prise en compte !
                            </Typography> 
                        </div>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            Les candidats recevront plusieurs résultats par la suite. Ceux-ci ne sont pas définitifs et peuvent être modifiés par Tsahal tout au long du processus.
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            <strong>✔️ Dapar - </strong>Note de 10 à 90 (par dizaine) qui représente les résultats aux examens psychotechniques. De plus, les candidats recevront une note de 1 à 9 pour chaque partie des examens psychotechniques.
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            <strong>✔️ Simoul Ivrit - </strong>Note sur le niveau d’hébreu de 5 (minimum) à 8 (maximum). Les candidats qui obtiennent 5 ou 6 devront commencer leurs services par <Link to='/michve-alon' style={{color: '#1398aa'}}>Michve Alon</Link>  (exception faite pour les soldats des branches religieuses). 
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            <strong>✔️ Profil Médicale - </strong> Voici les différents profils médicaux: 
                            <ul>
                                <li>97 et 82 : unités d’infanterie et unités d’élites.</li>
                                <li>72 : autres unités combattantes (tankiste par exemple)</li>
                                <li>64 et 45 : unités non-combattantes</li>
                                <li>25 : candidat qui se porte volontaire dans Tsahal après avoir reçu un profil 21.</li>
                                <li>24 : exempté temporairement du service militaire (post-opération).</li>
                                <li>21 : exempté du service militaire avec possibilité de se porter volontaire si Tsahal accepte.</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            <strong>✔️ Indice d'officier - </strong>Note sur la compatibilité du candidat de devenir officier. Les résultats sont - faible, moyen, élevé ou très élevé. Il est possible de changer cet indice à la fin des classes militaires (Tironout) ou à la fin des tests pré-officier.
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                            <strong>✔️ Impression de l’entretien individuel - </strong>Permet de déterminer dans quelle condition le candidat peut servir dans Tsahal lorsque l’accent est mis sur les unités combattantes et unités d’élites. 
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                            <strong>✔️ Kahas (si la note n’apparaît pas, c’est que le candidat n’en a pas) - </strong> Voici les différents Kahas: 
                            <ul>
                                <li>20 : présence de certains problèmes mineure sans influence.</li>
                                <li>40 : impossible d’arriver dans des unités combattantes ou de devenir officier.</li>
                                <li>41 : impossible d’arriver dans les unités aux fronts.</li>
                                <li>42 : impossible d’arriver dans les unités aux fronts et conditions de service 02.</li>
                                <li>43 : impossible d’arriver dans les unités aux fronts et conditions de service 03.</li>
                                <li>50 : affectation dans un système adapté. Cela concerne les personnes ayant un casier judiciaire ou ayant peu de motivation à servir ou encore aux candidats ayant eu 10 aux psychotechniques.</li>
                                <li>60 : exempté de service militaire car jugé incompatible avec le système.</li>
                            </ul>
                        </Typography>
                    </Container>
                </div>
                <Footer />
                </Fade>
            </div>
        );
    };
    
    export default TsavRichon;
    