import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';


const AtoudaOlimAkadem = () => {

    useEffect(() => {
        document.title = 'Atouda Olim et Akademisatzia | Tsahal Connection';
      }, []);
      
    return (
        <div>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Services</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Atouda Olim et Akademisatzia</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                Les nouveaux immigrants et les lycéens israéliens qui ont déjà commencé a étudier un diplôme académique peuvent repousser leurs entrées à l’armée dans le cadre des études. 
                Ce report est accepté par Tsahal tant que les candidats répondent aux conditions d’admission. De plus, si une personne ne valide pas une année d’étude, l’armée est dans son droit de lui annuler son report et de lui donner une affectation selon les besoins de Tsahal.
                </Typography>
                <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#eafcff', borderColor: '#eafcff', marginBottom: '2rem', marginTop: '1rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1', fontSize: '20px', color: '#2f3036', fontWeight: '600', padding: '20px'}}>
                    ⬇️ Pour télécharger la liste des études possible via Akademisatzia, cliquez <Link to="https://drive.google.com/file/d/1-0YLccHZ4p154pK9MmSVF9fVbsc9E9Az/view" target="_blank">ICI</Link>.
                    </Typography> 
                </div>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                    Report du service pour les études
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500',color: '#2f3036', marginBottom: '1rem'}}>
                    Les nouveaux immigrants et les lycéens israéliens qui ont déjà commencé a étudier un diplôme académique peuvent repousser leurs entrées à l’armée dans le cadre des études. 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '0.5rem', fontWeight: '700'}}>
                    Lycéens israéliens 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                 Les lycéens peuvent aussi repousser leurs entrées à l’armée s’ils ont validé 30% de la licence (si licence ingénierie) jusqu’à la fin de la classe de youd beth (terminale) ou 50% (si licence différente d’ingénierie).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '0.5rem', fontWeight: '700'}}>
                    Nouveaux immigrants - Atouda Olim
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Les nouveaux immigrants ont la possibilité de pouvoir étudier un diplôme académique <strong>de premier cycle uniquement</strong> avant leurs services militaires, en demandant un report du service militaire au lendemain de chaque fin d’année d’études. Pour effectuer la demande, il est obligatoire de commencer les études pas plus tard que 18 mois après l’arrivée en Israël.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    Voici la liste de ce qui est reconnu comme un cycle études : 
                    <ul>
                        <li><strong>Année préparatoire pour nouveaux immigrants (MECHINAT OLIM) dans les lieux suivants :</strong> Université hébraïque de Jérusalem, université du Technion ou les universités de Tel Aviv/Bar Ilan (ces deux universités partagent la même année préparatoire). La mechina du Machon Lev est reconnu. Il est important d'enchainer directement avec les études de niveaux supérieurs sous peine de se faire refuser dans le programme. </li>
                        <li><strong>Licence académique (Toar Rishon) :</strong>  peu importe le domaine. Cependant, pour les candidats souhaitant devenir officiers académiques, il faut choisir un domaine d’études parmi la liste plus bas.</li>
                        <li><strong>Diplôme technologique (<span style={{fontFamily: 'Rubik'}}>הנדסאי</span>) :</strong> ces diplômes représentent l'équivalent du BTS. Il est possible d'étudier pendant une année (Youd gimel) ou deux (youd daleth).</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem',color: '#2f3036' }}>
                    De plus les nouveaux immigrants souhaitant intégrer ce programme doivent effectuer un service obligatoire de six mois minimum (c’est-à-dire être arrivé en Israël jusqu’à l’âge de 21 ans compris) et avoir obtenu le baccalauréat à l’étranger.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem',color: '#2f3036' }}>
                    Afin de pouvoir continuer leurs études, les étudiants sont dans l’obligation de repousser leurs études chaque année (jusqu’à 1er août). Il faut chaque année envoyer les formulaires suivants (sur le site de l’armée ou via mail) :
                    <ul>
                        <li><Link to="https://drive.google.com/file/d/10UqCwVc-nUcNmlfL-WgmiU0gTdwNOUlN/view" target="_blank" style={{color: '#2f3036'}}>Formulaire de demande du report de service.</Link></li>
                        <li>Autorisation d’études de l’année suivante (à demander auprès de l’établissement scolaire). Il doit obligatoirement être indiqué les points suivants:
                            <ul>
                                <li>Date de début et date de fin des études.</li>
                                <li>Type d’études.</li>
                                <li>Nombre de UE validé par le candidat et nombre de UE restant pour valider les études (UE=Nekoudot Zhout).</li>
                            </ul>
                        </li>
                        <li>Bulletin de notes (à demander auprès de l’établissement scolaire)</li>
                        <li><Link to="https://drive.google.com/file/d/1-3VZbKzngciaiMsM3J2xeY6X6GhmSf3T/view?usp=sharing" target="_blank" style={{color: '#2f3036'}}>Formulaire d'Atouda olim-doit être rempli sans erreur !</Link></li>
                    </ul>
                </Typography>
                <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px' }}>
                            ⚠️ Les étudiants qui arrêtent leurs études pour différentes raisons sont dans l’obligation de s’engager à l’armée et effectuer leurs services obligatoires. Des étudiants changeant de parcours d'étude n'ont pas la garantie de voir leurs demandes pour rester dans le parcours d'Atouda Olim, <strong>surtout si cette demande retarde la fin des études d'une année.</strong>

                            </Typography> 
                        </div>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Option à la fin des études
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    Pendant la période où le candidat étudie et repousse son service militaire, il est possible pour lui d'effectuer des tests pour d'autres unités, excepté la formation de pilote (miyun tays) qui gèle toutes les demandes de tests. Il existe trois options à la fin des études pour les candidats des deux cas de figures cités :
                    <ul style={{marginTop: '1rem'}}>
                        <li>Les candidats ayant effectué un parcours académique et répondants aux critères du parcours Akademisatzia (voir ci-dessous) et donc recevront la possibilité de devenir officier académique s’ils le souhaitent (ils ne sont pas obligés).</li>
                        <li>Les candidats ayant effectué un parcours technologique (youd gimel-youd daleth) ont la possibilité d'utiliser leurs diplômes acquis et de signer soit 9 mois (s'ils étudient une année uniquement) soit 15 mois supplémentaires (deux années d'études), en plus du temps de service obligatoire. Cependant dans de nombreux cas les unités annulent la signature du contrat supplémentaire car ils n’ont pas besoin d’autant de soldats. </li>
                        <li>Si les candidats ne souhaitent pas devenir officier académique ou qu’ils ne vont pas au bout de leurs études, ils recevront une affectation en fonction des besoins de l’armée et la durée va dépendre de la loi du service obligatoire (pour les lycéens israéliens) et de l’âge d’arrivée & statut maritale (pour les nouveaux immigrants).</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700', color: '#2f3036' }}>
                    Akademisatzia
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '0.5rem', fontWeight: '700'}}>
                Conditions d’admission
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟩 Note de 60 au Dapar (Psychotechniques de l’examen du Tsav Rishon).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟩 Validation d’une licence académique avant l’incorporation militaire.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟩 Moyenne minimum de 75.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟩 Durée de service militaire complète selon la loi du service militaire obligatoire en Israël (le temps de service des filles est le même que celui des hommes hormis pour les nouveaux immigrants). 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟩 Obligation de service supplémentaire pour une durée de 24 mois (Kéva).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟩 Validation d’un profil de sécurité « Sodi Beyoter ». 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟩 Validation des tests pré armée qui vont dépendre de l’unité et du corps affecté.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    🟩 Si le candidat vient avec un diplôme étranger et souhaite rejoindre le parcours Akademisatzia, il devra faire traduire son diplôme par le ministère de l’éducation israélienne. Il faut cependant avoir un niveau d’hébreu de qualité afin de rejoindre ce parcours.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '0.5rem', fontWeight: '700'}}>
                Avantages
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟪 Devenir officier académique dans Tsahal.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟪 Affectation dans le domaine étudié (cependant ce n’est pas toujours la spécialité étudiée).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    🟪 Bonus à la signature du contrat de 8500 NIS.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '26px', marginBottom: '0.5rem', fontWeight: '700'}}>
                    Processus
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Pour les personnes avec un diplôme étranger, les démarches commenceront après avoir effectué les premiers entretiens militaire et avoir fait reconnaitre leurs diplômes. Pour les participants au programme Atouda Olim, les démarches commenceront réellement pendant la dernière année d’études. Pour les lycéens qui ont commencé un diplôme universitaire, certaines démarchent se font déjà en classe de terminale.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟦 Participation au Kenes Akademisatzia où seront expliqué les postes possibles, les conditions d’admission en fonction du type d’études-janvier de la dernière année d’étude.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟦 Remplir le formulaire Akademisatzia depuis le site Mitgaisim. Les lycéens qui ont commencés un diplôme anniversaire doivent remplir ce formulaire jusqu’au mois de septembre de la classe de terminale. Les participants à Atouda Olim doivent remplir ce formulaire entre janvier et mars lors de leurs dernières années d’études.   
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟦 Entretiens pour les projets de Master - entre avril et juin de la dernière année d’étude.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟦 Entretiens pour Akademisatzia afin d’examiner les compétences professionnelles, académiques et interpersonnelles du candidat-entre juillet et août de la dernière année d’étude.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟦 Affectation au mois de septembre qui suit la fin des études académiques. 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    🟦 Signature du contrat d’officier académique et tests de sécurité pour obtenir le profil « Sodi Beyoter » -entre septembre et novembre qui suit la fin des études académique. 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    🟦 Incorporation à Tsahal en fonction de la date de la fin des études académiques indiqués sur le formulaire Akademisatzia. Les candidats commenceront leurs services par des classes générales (Tironout 02 ou 03) et seront affectés par la suite dans le corps de l’armée décidé lors de la signature du contrat. L’affectation définitive dans l’unité se fera par l’administration en charge de l’unité et ce en fonction des compétences académiques et du diplôme du candidat- entre décembre et avril qui suit la fin des études académiques.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '700', marginBottom: '2rem', color: '#2f3036'}}>
                    Toutes les études reconnus par le programme Atouda Akademit sont reconnu par Akademisatzia.
                </Typography>
            </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default AtoudaOlimAkadem;
  