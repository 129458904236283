import React, { useEffect, useState } from 'react';
import Navbar from "../components/Features/Navbar";
import { Container, Button } from '@mui/material';
import { Card, Row, Col } from 'react-bootstrap'; // Use react-bootstrap for Card
import { Link } from 'react-router-dom';



import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
import Footer from '../components/Home/Footer';
import "./../styles/expert-co.css";
import { Fade } from 'react-awesome-reveal';


const ExpertConnection = () => {
    const [activeSet, setActiveSet] = useState(1);
    const [open, setOpen] = useState(true); // State to manage dialog open/close

    //POP UP    
    const handleClose = () => {
        setOpen(false);
    };
    // END POP UP

    const handleSetChange = (setNumber) => {
        setActiveSet(setNumber);
    };

    const renderCards = (setNumber) => {
        switch (setNumber) {
            case 1:
                return (
                    <Card className="text-center border-1 shadow">
                        <Card.Body>
                            <Card.Title style={{fontSize: '3rem', fontFamily: 'Montserrat', fontWeight: '700', marginBottom: '1rem', marginTop: '1rem'}} >Classique</Card.Title>
                            <Card.Text style={{fontSize: '1rem', fontFamily: 'Montserrat', fontWeight: '400', textDecoration: 'underline'}}>Mahal/Olim Bodedim (sans parents)</Card.Text>
                                <Card.Text style={{fontSize: '5rem', fontFamily: 'Montserrat', fontWeight: '700'}}><span style={{fontSize: '30px'}}>₪</span>499<span style={{fontSize: '10px'}}>TTC</span></Card.Text>
                                <Card.Text style={{fontSize: '1rem', fontFamily: 'Montserrat', fontWeight: '500'}}>ou <strong>2X 249.5₪</strong> sans frais</Card.Text>
                                <hr style={{marginBottom: '2rem'}}/>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Contact avec d'anciens soldats</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Préparations aux tests militaires</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Aide administrative</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Journée de préparation</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Réponses aux questions via Whatsapp</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Cadeaux avant l'entrée à l'armée</Card.Text>
                                <Link to='/suivi-classique'><Button className='purchase' style={{color: 'white', backgroundColor:'#2593C4', width: '150px', fontSize: '16px', marginTop: '3rem'}}>S'inscire</Button></Link>
                        </Card.Body>
                    </Card>
                );
            case 2:
                return (
                    <Card className="text-center border-1 shadow">
                        <Card.Body>
                            <Card.Title style={{fontSize: '3rem', fontFamily: 'Montserrat', fontWeight: '700', marginBottom: '1rem', marginTop: '1rem'}}>Expert</Card.Title>
                            <Card.Text style={{fontSize: '1rem', fontFamily: 'Montserrat', fontWeight: '400', textDecoration: 'underline'}}>Olim avec parents</Card.Text>
                                <Card.Text style={{fontSize: '5rem', fontFamily: 'Montserrat', fontWeight: '700'}}><span style={{fontSize: '30px'}}>₪</span>999<span style={{fontSize: '10px'}}>TTC</span></Card.Text>
                                <Card.Text style={{fontSize: '1rem', fontFamily: 'Montserrat', fontWeight: '500'}}>ou <strong>4X 249.75₪</strong> sans frais</Card.Text>
                                <hr style={{marginBottom: '2rem'}}/>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Contact avec d'anciens soldats</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Préparations aux tests militaires</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Aide administrative</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Assistance pour les formulaires</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Soutien aux parents</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Rédaction de documents pour Tsahal</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem', marginBottom: '1.5rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Orientation sur les postes dans Tsahal</Card.Text>
                                <Card.Text style={{textAlign: 'left', fontFamily: 'Montserrat', fontSize: '1rem', marginLeft: '1rem'}}><span style={{marginRight: '0.5em'}}>✔️</span> Reponses aux questions via Whatsapp</Card.Text>
                                <Link to='/suivi-expert'><Button className='purchase' style={{color: 'white', backgroundColor:'#2593C4', width: '150px', fontSize: '16px', marginTop: '3rem'}}>S'inscire</Button></Link>
                        </Card.Body>
                    </Card>
                );
            default:
                return null;
        }
    };

    

    useEffect(() => {
        document.title = 'Expert Connection | Tsahal Connection';
    }, []);
    return (
        <div className='expert-co'>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                marginTop: '2rem',
            }}>
                <h1>
                    Expert Connection
                </h1>
                <h2>
                    est la branche d'accompagnement individuel du projet Tsahal Connection.
                </h2>
                <Container maxWidth="lg" style={{ marginTop: '3rem', marginBottom: '4rem' }}>
                    <Row>
                        <Col md={4}>
                            <Card className="border-0">
                                <Card.Body>
                                    <Card.Title style={{color: '#2593C4', fontFamily: 'Montserrat', fontWeight: '700'}}>Nous nous adaptons à vous.</Card.Title>
                                    <Card.Text style={{fontFamily: 'Montserrat'}}>
                                        Notre équipe dévouée propose un soutien adapté à vos besoins, faisant preuve d'humanité et d'honnêteté.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-0">
                                <Card.Body>
                                    <Card.Title style={{color: '#2593C4', fontFamily: 'Montserrat', fontWeight: '700'}}>Plus que des informations.</Card.Title>
                                    <Card.Text style={{fontFamily: 'Montserrat'}}>
                                        Notre connaissance de l'administration et de la mentalité de l'armée israélienne permet de faciliter votre intégration dans les rangs de Tsahal.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="border-0">
                                <Card.Body>
                                    <Card.Title style={{color: '#2593C4', fontFamily: 'Montserrat', fontWeight: '700'}}>Vous accompagner, notre fierté.</Card.Title>
                                    <Card.Text style={{fontFamily: 'Montserrat'}}>
                                        Nos objectifs principaux sont votre réussite et votre épanouissement dans les rangs de Tsahal ainsi que dans la société israélienne.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container maxWidth="md" style={{marginBottom: '1rem'}}>
                <h3>Les suivis <span style={{color: 'black', backgroundColor: '#E1EFFF', fontWeight: '800', letterSpacing: '1.2px'}}>Expert Connection</span></h3>
                </Container>
                <Container maxWidth="md" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                <Row>
                    <Col md={12} xs={12} className="d-flex flex-row flex-wrap align-items-center justify-content-center" style={{marginBottom: '3rem',}}>
                        <Button
                            variant="outline-secondary"
                            style={{
                                borderRadius: '20px',
                                border: '1px solid #6c757d',
                                margin: '5px',
                                color: activeSet === 1 ? 'black': '#e0e0e0',
                                backgroundColor: activeSet === 1 ? '#f8f9fa' : 'transparent',
                                }}
                            onClick={() => handleSetChange(1)}
                        >
                            Suivi Classique
                        </Button>
                        <Button
                            variant="outline-secondary"
                            style={{
                                borderRadius: '20px',
                                border: '1px solid #6c757d',
                                margin: '5px',
                                color: activeSet === 2 ? 'black': '#e0e0e0',
                                backgroundColor: activeSet === 2 ? '#f8f9fa' : 'transparent',
                                }}
                            onClick={() => handleSetChange(2)}
                        >
                            Suivi Expert
                        </Button>   
                    </Col>
                </Row>
                    <Col md={{ span: 6, offset: 3 }} xs={12}>
                        {renderCards(activeSet)}
                    </Col>
            </Container>
            <div style={{backgroundColor: '#FAFAFA', width: '100%', padding: 0}}>
                <Container maxWidth="md" style={{marginTop: '1.5rem', marginBottom: '3rem'}}>
                    <h3>Les services <span style={{color: 'black', backgroundColor: '#E1EFFF', fontWeight: '800', letterSpacing: '1.2px'}}>Expert Connection</span></h3>
                </Container>
                <Container maxWidth="xl" style={{ marginTop: '3rem', marginBottom: '1rem'}}>
                    <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Col md={3} className='mb-4'>
                            <Card className="text-center border-1 shadow d-flex flex-column h-100" style={{borderRadius: '20px'}}>
                                <Card.Body>
                                    <Card.Title style={{fontSize: '1.5rem', fontFamily: 'Montserrat', fontWeight: '700', marginBottom: '2rem', marginTop: '1rem'}}>Entretien <br/> individuel</Card.Title>
                                    <Card.Text style={{fontSize: '4rem', fontFamily: 'Montserrat', fontWeight: '700', color:'#16163F'}}>₪249<span style={{fontSize: '10px'}}>TTC</span></Card.Text>
                                    <Link to='/entretien-individuel'><Button className='purchase' style={{color: 'white', backgroundColor:'#2593C4', width: '150px', fontSize: '16px', marginTop: '3rem'}}>S'inscire</Button></Link>
                                </Card.Body>    
                            </Card>
                        </Col>
                        <Col md={3} className='mb-4'>
                            <Card className="text-center border-1 shadow d-flex flex-column h-100" style={{borderRadius: '20px'}}>
                            <Card.Body>
                                    <Card.Title style={{fontSize: '1.5rem', fontFamily: 'Montserrat', fontWeight: '700', marginBottom: '2rem', marginTop: '1rem'}}>Simulation <br/>Tsav Richon/Yom Hamea</Card.Title>
                                    <Card.Text style={{fontSize: '4rem', fontFamily: 'Montserrat', fontWeight: '700', color:'#16163F'}}>₪299<span style={{fontSize: '10px'}}>TTC</span></Card.Text>
                                    <Link to='/simulation-tsav-richon-yom-hamea'><Button className='purchase' style={{color: 'white', backgroundColor:'#2593C4', width: '150px', fontSize: '16px', marginTop: '3rem'}}>S'inscire</Button></Link>
                                </Card.Body>    
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container maxWidth="xl" style={{marginBottom: '1rem' }}>
                    <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} className='mb-4'>
                                <Card className="text-center border-1 shadow d-flex flex-column h-100" style={{borderRadius: '20px'}}>
                                <Card.Body>
                                    <Card.Title style={{fontSize: '1.5rem', fontFamily: 'Montserrat', fontWeight: '700', marginBottom: '2rem', marginTop: '1rem'}}>Evaluation physique & mentale</Card.Title>
                                    <Card.Text style={{fontSize: '4rem', fontFamily: 'Montserrat', fontWeight: '700', color:'#16163F'}}>₪499<span style={{fontSize: '10px'}}>TTC</span></Card.Text>
                                    <Link to='/evaluation-physique-mentale'><Button className='purchase' style={{color: 'white', backgroundColor:'#2593C4', width: '150px', fontSize: '16px', marginTop: '3rem'}}>S'inscire</Button></Link>
                                </Card.Body>    
                                </Card>
                            </Col>
                            <Col md={3} className='mb-4'>
                                <Card className="text-center border-1 shadow d-flex flex-column h-100" style={{borderRadius: '20px'}}>
                                    <Card.Body>
                                        <Card.Title style={{fontSize: '1.5rem', fontFamily: 'Montserrat', fontWeight: '700', marginBottom: '2rem', marginTop: '1rem'}}>Orientation individuelle sur les postes</Card.Title>
                                        <Card.Text style={{fontSize: '4rem', fontFamily: 'Montserrat', fontWeight: '700', color:'#16163F'}}>₪699<span style={{fontSize: '10px'}}>TTC</span></Card.Text>
                                        <Link to='/orientation-individuelle-postes'><Button className='purchase' style={{color: 'white', backgroundColor:'#2593C4', width: '150px', fontSize: '16px', marginTop: '3rem'}}>S'inscire</Button></Link>
                                    </Card.Body>        
                                </Card>
                            </Col>
                        </Row>
                    </Container>
            </div>
            </div>

            <div className="outer-container">
                <div className="you-want">
                    <Container className="d-flex justify-content-center align-items-center">
                        <Col md={6} className="mx-auto">
                            <Card className="special-card shadow" style={{marginTop: '4rem'}}>
                                <Card.Body style={{padding: '30px 16px 30px 16px'}}>
                                    <Card.Text style={{fontSize: '3rem', fontFamily: 'Montserrat', fontWeight: '700', textAlign: 'center', color: '#16163F', marginTop: '3rem'}}>
                                    Dites nous ce que vous voulez
                                    </Card.Text>
                                    <Card.Text style={{fontSize: '1rem', fontFamily: 'Montserrat', textAlign: 'center', letterSpacing: '1.2px', marginTop: '2rem', marginBottom: '2rem', color: 'black', padding: "0px 2rem 0px 2rem"}}>
                                    Vous souhaitez quelque chose qui n'apparaît pas dans la liste ? Contactez-nous afin de mettre en place un suivi ou service sur mesure.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Container>
                </div>
                <div style={{minHeight: '300px', marginTop: '-10%'}}>

                </div>
            </div>

            <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <h3>Ils en parlent <span style={{color: 'black', fontWeight: '800', letterSpacing: '1.2px'}}>mieux que nous</span></h3>
            </Container>
            <Container maxWidth="xl" style={{marginBottom: '3rem' }}>
                    <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={4} className='mb-4'>
                                <Card className="text-center border-1 shadow d-flex flex-column h-100" style={{borderRadius: '0px'}}>
                                <Card.Body className="d-flex flex-fill flex-column">
                                <Card.Text style={{fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'italic', fontWeight: '300', marginTop: '2rem', letterSpacing: '1.2px'}}>“L'équipe est patiente, bienveillante et souriante tout en restant droite et honnête avec la réalité du service militaire, leur expérience du monde pré-armée est inégalable.”</Card.Text>
                                <hr style={{width: '70px', marginTop: '3rem'}} />
                                <Card.Text style={{fontFamily: 'Roboto', fontSize: '16px', fontWeight: '300', marginTop: '2rem', letterSpacing: '1.2px', textAlign: 'left'}}>Ethan F. - <strong>Suivi Expert</strong></Card.Text>
                                </Card.Body>    
                                </Card>
                            </Col>
                            <Col md={4} className='mb-4'>
                                <Card className="text-center border-1 shadow d-flex flex-column h-100" style={{borderRadius: '0px'}}>
                                <Card.Body className="d-flex flex-fill flex-column">
                                <Card.Text style={{fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'italic', fontWeight: '300', marginTop: '2rem', letterSpacing: '1.2px'}}>“ Expert Connection arrive à jongler entre une connaissance parfaite du monde militaire et une écoute des futurs soldats. Cela m'a permis de trouver des objectifs à l'armée et de tout faire pour y arriver.”</Card.Text>
                                <hr style={{width: '70px', marginTop: '3rem'}} />
                                <Card.Text style={{fontFamily: 'Roboto', fontSize: '16px', fontWeight: '300', marginTop: '2rem', letterSpacing: '1.2px', textAlign: 'left'}}>Eden S. - <strong>Suivi Avancé</strong></Card.Text>                
                                </Card.Body>    
                                </Card>
                            </Col>
                            <Col md={4} className='mb-4'>
                                <Card className="text-center border-1 shadow d-flex flex-column h-100" style={{borderRadius: '0px'}}>
                                <Card.Body className="d-flex flex-fill flex-column">
                                    <Card.Text style={{fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'italic', fontWeight: '300', marginTop: '2rem', letterSpacing: '1.2px'}}>“Grâce à Expert Connection, j'ai pu rentrer à Mahal alors que c'était loin d'être gagné. Surtout, ils m'ont rassuré et préparer à toutes les étapes jusqu'à l'entrée à l'armée.”</Card.Text>
                                    <hr style={{width: '70px', marginTop: '3rem'}} />
                                    <Card.Text style={{fontFamily: 'Roboto', fontSize: '16px', fontWeight: '300', marginTop: '2rem', letterSpacing: '1.2px', textAlign: 'left'}}>Simon B. - <strong>Suivi Mahal</strong></Card.Text> 
                                </Card.Body>        
                                </Card>
                            </Col>
                        </Row>
                    </Container>

            <Footer />
            </Fade>
        </div>
    );
};

export default ExpertConnection;