import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, useLocation } from 'react-router-dom';
import logo from './../../images/Logo Tsahal Conection.png';
import IconMenu from './../../images/icons/Menu.svg'
import './../../styles/navbar.css';
import logoExpertCo from './../../images/logos/LogoExpertConnection.png';
import { useTheme, useMediaQuery } from '@mui/material';

const navItems = [
  { text: 'Accueil', link: '/' },
  { text: 'À propos', link: '/apropos', subItems: [
    { text: 'Notre Histoire', link: '/notre-histoire' },
    { text: 'Nos Actions', link: '/nos-actions' },
    { text: 'Mentions Légales', link: '/mentions-legales' },
  ]},  
  { text: 'Services', link: '/services', subItems: [
    {text: 'Mahal', link: '/mahal' },
    {text: 'Service Complet', link: '/service-complet' },
    {text: 'Garin Tzabar', link: '/garin-tzabar' },
    {text: 'Atoudat Olim & Akademisatzia', link: '/atouda-olim-akademisatzia' },
    {text: 'Atoudat Akademit & Atidim', link: '/atouda-akademit-atidim' },
    {text: 'Youd Guimel - Youd Daleth', link: '/youdguimdel-dalet' },
    {text: 'Haredim', link: '/haredim' },
  ] },
  { text: 'Convocations', link: '/convocations', subItems: [
    {text: 'Tsav Richon', link: '/tsav-richon' },
    {text: 'Yom Hamea', link: '/yom-hamea' },
    {text: 'Miyunim', link: '/miyunim' },
    {text: 'Yom Sayerot', link: '/yom-sayerot' },
    {text: 'Miyun Lohamot', link: '/miyun-lohamot' },
    {text: 'Giyus', link: '/giyus' },

  ]},
  { text: 'Postes', link: '/postes' },
  { text: 'Expert Connection', link: '/expert-connection' },
  { text: 'Annexes', link: '/annexe', subItems: [
    {text: 'Mechinot', link: '/mechinot' },
    {text: 'Statut de résident', link: '/status-resident' },
    {text: 'Enfant Unique', link: '/enfant-unique' },
    {text: 'Tironout & Qualification', link: '/tironout' },
    {text: 'Michve Alon', link: '/michve-alon' },
    {text: 'Conditions de service', link: '/conditions-service' },
    {text: 'Pratique de la Religion', link: '/religion' },
    {text: 'Hayal Boded & Salaires', link: '/hayal-boded' },
    {text: 'Carnet d\'adresses & Forms', link: '/carnet-forms' },

  ] },
  { text: 'Ach Gadol', link: '/ach-gadol' },
  // { text: 'Espace Membre', link: '/login' },
];
function BasicExample() {
  const [showDropdown, setShowDropdown] = useState(null);
  const [changeOrder, setChangeOrder] = useState(false);
  const [marginItems, setMarginItems] = useState(0)
  const location = useLocation();
  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

  const getNavLinkClass = (path) => {
    return location.pathname === path ? "nav-link-active" : "";
  };

  // Function to check if the current path is among subitem paths
  const isParentActive = (subItems) => {
    return subItems.some(subItem => location.pathname === subItem.link);
  };

  useEffect(() => {
    const handleResize = () => {
        const newOrder = window.innerWidth < 991 ? -1 : 1
        setChangeOrder(newOrder)
        const newMargin = window.innerWidth < 991 ? 0 : 0.6
        setMarginItems(newMargin)
    };


    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  const expertPaths = [
    '/expert-connection',
    '/suivi-classique',
    '/suivi-expert',
    '/entretien-individuel',
    '/simulation-tsav-richon-yom-hamea',
    '/evaluation-physique-mentale',
    '/orientation-individuelle-postes'
  ];
  
  const isExpertConnection = expertPaths.includes(location.pathname);
  

  return (
    <Navbar expand="lg" className="bg-white" style={{ marginTop: '30px'}}>
      <Container className='my-nav'>
        <NavLink to= {isExpertConnection ? '/expert-connection' : '/'}>
          <img src={isExpertConnection ? logoExpertCo : logo} 
          alt="Logo"
          height={isMediumOrSmaller ? '80' : '120'}
          />
        </NavLink>
        <Navbar.Toggle id="mytoggler" aria-controls="basic-navbar-nav" style={{border: 'none', outline: 'none', boxShadow: 'none', order: `${changeOrder}`}}>
          <img src={IconMenu} alt="Menu Icon" style={{ width: '20px', height: '9px'}} />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          
        <Nav className="m-auto">
            {navItems.map((item, index) => (
              item.subItems ? (
                <NavDropdown
                  key={index}
                  title={item.text}
                  id={`nav-dropdown-${index}`}
                  className={`nav-main-item ${isParentActive(item.subItems) ? 'active-parent' : ''}`} // Apply active parent class conditionally
                  style={{ fontFamily: 'Montserrat', fontWeight: '600', marginRight: `${marginItems}rem`, flexDirection: 'column'}}
                  onMouseEnter={() => setShowDropdown(index)}
                  onMouseLeave={() => setShowDropdown(null)}
                  show={showDropdown === index}
                >
                  {item.subItems.map((subItem, subIndex) => (
                    <NavDropdown.Item
                      key={subIndex}
                      as={NavLink}
                      to={subItem.link}
                      className={`nav-dropdown-item ${getNavLinkClass(subItem.link)}`} // Apply specific class for dropdown items
                      style={{ fontFamily: 'Montserrat', fontWeight: '500', marginTop: '0.5rem', marginBottom: '0.5rem', textAlign: 'center'}}
                    >
                      {subItem.text}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ) : (
                <Nav.Link
                  key={index}
                  as={NavLink}
                  to={item.link}
                  className={`nav-main-item ${getNavLinkClass(item.link)}`} // Main item hover and active
                  style={{ fontFamily: 'Montserrat', fontWeight: '600', marginRight: '0.6rem', color: 'black'}}
                >
                  {item.text}
                </Nav.Link>
              )
            ))}
            <a href="https://zfrmz.com/FTnbHM1LHl4BWoGiMEqo" target='_blank'><button style={{backgroundColor: '#9A1FD2', borderRadius: '30px', border: '0px', padding: '0.5rem', color: 'white', fontWeight: '600', paddingRight: '1rem', paddingLeft: '1rem', fontFamily: 'Montserrat'}}>Contactez-nous</button></a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;

