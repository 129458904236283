import React, { useEffect } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
import Footer from '../../components/Home/Footer';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';



const data = [
    {
      type: "Enfant unique de parents mariés",
      col1: "À remplir",
      col2: "À remplir",
      col3: "À remplir",
      col4: "Ne pas remplir",
      col5: "Ne pas remplir",
    },
    {
      type: "Enfant unique de parents divorcés (mais contact avec eux)",
      col1: "À remplir",
      col2: "À remplir",
      col3: "À remplir",
      col4: "Ne pas remplir",
      col5: "Ne pas remplir",
    },
    {
      type: "Enfant unique de parents divorcés et aucun contact avec un des parents depuis deux ans minimum",
      col1: "À remplir",
      col2: "À remplir par le parent qui est en contact avec le futur soldat",
      col3: "À remplir",
      col4: "À remplir",
      col5: "À remplir par le parent qui est en contact avec le futur soldat",
    },
    {
      type: "Enfant unique d’un parent uniquement et en contact avec lui",
      col1: "À remplir",
      col2: "Remplir (uniquement le parent qui n’a pas d’autre enfant)",
      col3: "À remplir",
      col4: "Ne pas remplir",
      col5: "Ne pas remplir",
    },
    {
      type: "Enfant unique par un parent dont il n’est plus en contact avec depuis deux ans minimum",
      col1: "À remplir",
      col2: "À remplir par le parent qui est en contact avec le futur soldat",
      col3: "À remplir",
      col4: "À remplir",
      col5: "À remplir par le parent qui est en contact avec le futur soldat",
    },
    {
      type: "Enfant unique qui n’est pas en contact avec les deux parents depuis deux ans minimum",
      col1: "À remplir",
      col2: "À remplir par le tuteur légal",
      col3: "À remplir",
      col4: "À remplir",
      col5: "À remplir par le tuteur légal",
    },
    {
      type: "Enfant unique et les parents habitent à l’étranger",
      col1: "À remplir",
      col2: "À remplir",
      col3: "À remplir",
      col4: "Ne pas remplir",
      col5: "Ne pas remplir",
    },
  ];

  
const EnfantUnique = () => {

    useEffect(() => {
        document.title = 'Enfant Unique | Tsahal Connection';
    }, []);
    return (
        <div>
            <Navbar />
            <Fade>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Annexes</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Enfant Unique</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '3rem', fontWeight: '500', color: '#2f3036'}}>
                        Les garçons ou filles qui n’ont pas de frères et sœurs des mêmes parents sont considérés comme enfant unique par Tsahal. Les personnes possédantes des parents divorcés et n’ayant aucun frère/sœur des deux parents ou demi-frères/sœur si les parents divorcés ont aussi un statut d’enfant unique.
                        Dans ce cas-là, ces personnes  doivent remplir un formulaire (disponible en <Link to='https://1drv.ms/b/s!AgVanePzBHI9jRyUwcBOarvB_mR1?e=vtftWO' target="_blank">anglais</Link> ou en <Link to='https://1drv.ms/b/s!AgVanePzBHI9jRjbr2s0a2p-ldi8?e=Iv9Yu5' target="_blank">hébreu</Link>) s’ils souhaitent intégrer une unité combattante. Les unités suivantes sont malgré tout disponibles d’accès sans avoir besoin de remplir le formulaire : combattant dans le système de défense aérienne (Hagana Avirit), commandant/e en charge des nouvelles recrues (Mefaked/et Kitat Tironim) et combattant aux frontières (Lohem/et Maavarim).
                        Il est possible de remplir ce formulaire directement à un centre de recrutement par l’officier en charge, cependant il n’est pas toujours présent. Souvent, la meilleure option est de le faire signer par un avocat privé.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '3rem', fontWeight: '700' }}>
                        Explication pour remplir le formulaire
                    </Typography>
                <TableContainer component={Paper} style={{marginBottom: '4rem'}}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Type d'enfant unique</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Appendix A Partie A</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Appendix A Partie B</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Appendix A Partie C</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Appendix B</TableCell>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600',fontFamily: 'Montserrat' }}>Appendix C</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                            <TableCell style={{ backgroundColor: '#EBEAFF', fontWeight: '600', fontFamily: 'Montserrat' }}>{row.type}</TableCell>
                            <TableCell style={{fontFamily: 'Montserrat'}}>{row.col1}</TableCell>
                            <TableCell style={{fontFamily: 'Montserrat'}}>{row.col2}</TableCell>
                            <TableCell style={{fontFamily: 'Montserrat'}}>{row.col3}</TableCell>
                            <TableCell style={{fontFamily: 'Montserrat'}}>{row.col4}</TableCell>
                            <TableCell style={{fontFamily: 'Montserrat'}}>{row.col5}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '1rem', fontWeight: '700' }}>
                    Regler le statut d'enfant unique à tord
                    </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        Depuis 2020, Tsahal attribue un statut d’enfant unique à certains candidats qui viennent en Israël sans famille (soldat seul). À cause de ce statut, les candidats ne peuvent rejoindre les unités combattantes (excepté celles citées plus haut). Ainsi, afin de prouver à l’armée que le candidat n’est pas enfant unique, celui-ci doit préparer les documents suivants : 
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                            <strong>✔️ Option 1</strong><br />
                            <ul>
                            <li>Acte de naissance apostillé du candidat.</li>
                            <li>Acte de naissance apostillé des frères/sœurs.</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                            <strong>✔️ Option 2</strong><br />
                            <ul>
                            <li>Acte de naissance du candidat (non-apostillé).</li>
                            <li>Acte de naissance des frères/sœurs (non-apostillé).</li>
                            <li>Attestation des parents en anglais/hébreu certifiant que le candidat n’est pas enfant unique et que plusieurs enfants sont issues de leurs unions.</li>
                            <li>Attestation du candidat en anglais/hébreu devant un avocat/notaire en Israël certifiant qu’il n’est pas enfant unique et qu’il possède des frères/sœurs issues des mêmes parents.</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                            <strong>✔️ Option 3</strong><br />
                            <ul>
                            <li>Attestation des parents en anglais/hébreu devant un notaire (ou en français mais avec une traduction d’un notaire en anglais/hébreu) certifiant que le candidat n'est pas enfant unique et que plusieurs enfants sont issues de leurs unions (<Link to="https://drive.google.com/file/d/1L7AW2eKhRiJ6PGpaYZMHmmOJMmrMY5oe/view" target='_blank'>voici un format exemple</Link>).</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                        Les candidats détenteurs de la nationalité israélienne et ayant des frères/sœurs des mêmes parents inscrits au consulat israélien de leurs pays de résidence seront à priori exemptés de préparer ces documents-là uniquement si les deux parents sont israéliens. Dans le cas où un des parents est israélien uniquement, ces mêmes candidats seront considérés comme enfant unique et devront fournir les justificatifs indiqués ci-dessus.
                        </Typography>
                </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
};

export default EnfantUnique ;
